module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/images/favicon.png","legacy":true,"lang":"en","name":"Passwd","short_name":"Passwd","dir":"auto","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","orientation":"any","start_url":"/","localize":[{"start_url":"/en/","lang":"en"},{"start_url":"/cs/","lang":"cs"},{"start_url":"/de/","lang":"de"},{"start_url":"/pl/","lang":"pl"},{"start_url":"/es/","lang":"es"},{"start_url":"/ru/","lang":"ru"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6934957672230dba96eb7262ebc973cf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/var/lib/gitlab-runner/builds/64oDQY8d/1/Web/passwd-microsite/src/localization","languages":["en","cs","de","pl","es","ru"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
